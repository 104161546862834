/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import './list-block.css';
import Block from './Block';

const ListBlock = () => {
  const listBlock = [
    { id: 1, title: 'Before College', content: 'Exploring multiple programing languages & tools' },
    { id: 2, title: ' College ', content: 'Master degree in Network and Telecommunication' },
    { id: 3, title: '2018-2021', content: 'Co-founder & Android developer in a startup' },
    { id: 4, title: 'Oct 2022 - Jun 2023', content: 'Microverse Full-Stack Web Developement School' },
    { id: 5, title: 'March 2023 - June 2023', content: 'Working as a Code Reviewer at Microverse ' },
    { id: 7, title: 'May 2023 - Present', content: 'Part-Time Front end developer volunteer' },
    { id: 8, title: 'June 2023 - Present', content: 'Working as a Technical OPS at CouponFollow' },
  ];

  const [currentBlockId, setCurrentBlockId] = useState(listBlock.length - 1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentBlockId === listBlock.length) {
        setCurrentBlockId(1);
      } else {
        setCurrentBlockId(currentBlockId + 1);
      }
    }, 8000);
    return () => {
      clearInterval(interval);
    };
  });
  return (
    <>
      <ul className="no-style list-blocks plausible-event-name=List+Block">
        {listBlock.map((block, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <div onClick={() => { setCurrentBlockId(block.id); }}>
              <Block name="Dynamic" id={block.id} data={block} currentBlockId={currentBlockId} />
            </div>
          </li>
        ))}
      </ul>
      <Block name="static" id={0} data={{ title: '', content: ' ' }} currentBlockId={currentBlockId} />
    </>
  );
};

export default ListBlock;
