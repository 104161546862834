import React, { useState } from 'react';
import './about.css';
import '../Utils/animations.css';
import {
  FaGithubSquare, FaLinkedin, FaAngellist, FaTwitterSquare,
} from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { useInView } from 'react-intersection-observer';
import ListBlock from '../ListBock.jsx/ListBlock';
import resume from '../../assets/files/Allassane_Dicko_Resume.pdf';

const About = () => {
  const [inViewTitles, setInViewTitles] = useState(false);
  const [inViewSkills1, setinViewSkills1] = useState(false);
  const [inViewSkills2, setinViewSkills2] = useState(false);
  const [inViewSkills3, setinViewSkills3] = useState(false);
  const [inViewSkills4, setinViewSkills4] = useState(false);
  const { ref: refTitles } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView) => { if (inView) setInViewTitles(true); },
  });

  const { ref: refSkills1 } = useInView({
    threshold: 0,
    onChange: (inView) => { if (inView) setinViewSkills1(true); },
  });
  const { ref: refSkills2 } = useInView({
    threshold: 0,
    onChange: (inView) => { if (inView) setinViewSkills2(true); },
  });
  const { ref: refSkills3 } = useInView({
    threshold: 0,
    onChange: (inView) => { if (inView) setinViewSkills3(true); },
  });
  const { ref: refSkills4 } = useInView({
    threshold: 0,
    onChange: (inView) => { if (inView) setinViewSkills4(true); },
  });

  return (
    <section id="about" className="section column wrap about">
      <div className="flex-center max-content-flex list-block-wrapper">
        <div className="overlay" />
        <ListBlock />
      </div>
      <main>
        <h2 ref={refTitles} className={`section-title animate animate-hide ${inViewTitles && 'slide-fade-from-top'}`}>About Me</h2>
        <p ref={refTitles} className={`animate animate-hide ${inViewTitles && 'slide-fade-from-right'}`}>
          Hello! My name is Allassane. I&apos;m a
          {' '}
          <span className="txt-underlined">certified full-stack web developer </span>
          {' '}
          with a startup background,
          dedicated to creating visually appealing and detail-oriented software solutions.
          <br />
          <br />
          Currently, I&apos;m
          {' '}
          <span className="txt-underlined">working as a Technical OPS</span>
          {' '}
          at CouponFollow and am actively engaged at
          {' '}
          Decode Analytic as part-time Front-end developper
          {' '}
          <br />
          <br />
          I worked as Code Reviewer at Microverse and
          {' '}
          I&apos;m thriving to developpe my skills by participating
          {' '}
          to in the #1000DayOfCode challenge
          <br />
          <br />
          I&apos;m a graduate of Abou Bekr Belkaid University of Tlemcen, where I earned my
          {' '}
          <span className="txt-underlined">Master&apos;s degree in Telecommunications Engineering</span>
          <br />
          <br />
          I also have experience as a co-founder and part-time
          {' '}
          <span className="txt-underlined">Android developer</span>
          {' '}
          at LND (a startup), where I worked for
          {' '}
          <span className="txt-underlined">3 years</span>
          .
          <br />
          <br />
          In my spare time, I enjoy reading self-improvement books, gaming with friends,
          and discussing tech and the universe.
        </p>

        <ul className={`no-style flex-center list-icons animate animate-hide ${inViewTitles && 'slide-fade-from-top'}`}>
          <li>
            <a href="mailto:dickoallassane.dev@gmail.com" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <GrMail />
            </a>
          </li>
          <li>
            <a href="https://github.com/Trast00" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaGithubSquare />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/dickoallassane/" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaLinkedin />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/AllassaneDicko0" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaTwitterSquare />
            </a>
          </li>
          <li>
            <a href="https://wellfound.com/u/allassane-dicko" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaAngellist />
            </a>
          </li>
        </ul>

        <ul className="no-style column list-about">
          <li>
            <h3>Front-end</h3>
            <ul ref={refSkills1} className={`no-style animate animate-hide ${inViewSkills1 && 'slide-fade-from-right'}`}>
              <li>React</li>
              <li>Redux</li>
              <li>JavaScript</li>
              <li>Kotlin(Android Studio)</li>
              <li>TypeScript</li>
              <li>HTML5</li>
              <li>CSS3</li>
              <li>TailwindCSS</li>
              <li>XML</li>
            </ul>
          </li>
          <li className="li-frame-work">
            <h3 className="txt-frame-work">Back-end</h3>
            <ul ref={refSkills2} className={`no-style animate animate-hide ${inViewSkills2 && 'slide-fade-from-right'}`}>
              <li>Ruby on Rails</li>
              <li>Ruby</li>
              <li>PostgreSQL</li>
              <li>MySQL</li>
              <li>Node.js</li>
              <li>Express</li>
              <li>MongoDB</li>
              <li>Mongoose</li>
              <li>RSpec(testing)</li>
            </ul>
          </li>
          <li>
            <h3>Tools & Skills</h3>
            <ul ref={refSkills3} className={`no-style animate animate-hide ${inViewSkills3 && 'slide-fade-from-right'}`}>
              <li>Git</li>
              <li>Code Review</li>
              <li>Webpack</li>
              <li>Deployment</li>
              <li>Testing</li>
              <li>Test Driven Development</li>
              <li>Design Mobile & Web</li>
            </ul>
          </li>
          <li>
            <h3>Soft-Skill</h3>
            <ul ref={refSkills4} className={`no-style animate animate-hide ${inViewSkills4 && 'slide-fade-from-right'}`}>
              <li>Leadership</li>
              <li>Deep work</li>
              <li>Project Management</li>
              <li>Pair programming </li>
              <li>Time management</li>
              <li>Team Work</li>
              <li>Self-Driven learning</li>
            </ul>
          </li>
        </ul>

        <button type="button" className="btn-action plausible-event-name=Download+Resume">
          <a href={resume} download="Allassane_Dicko_Resume.pdf" className="no-style">
            Download Resume
          </a>
        </button>
      </main>
    </section>
  );
};

export default About;
