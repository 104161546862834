import React from 'react';
import './headline.css';
import '../About/about.css';
import '../Utils/animations.css';
import {
  FaGithubSquare, FaLinkedin, FaAngellist, FaTwitterSquare,
} from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import resume from '../../assets/files/Allassane_Dicko_Resume.pdf';

const Headline = () => (
  <section id="headline" className="section column headline">

    <div className="max-content-flex">
      <div className="flex-center column container">

        <h1 className="name animate animate-hide animate-delay-1s slide-fade-from-bottom">Hi, I&apos;m Allassane</h1>
        <h2 className="role">Full Stack Web Developer</h2>

        <p className="animate animate-hide animate-delay-1s slide-fade-from-top">With a strong focus on creating efficient and detail-oriented solutions to address business needs.</p>

        <ul className="animate animate-hide animate-delay-1s slide-fade-from-top no-style flex-center list-icons">
          <li>
            <a href="mailto:dickoallassane.dev@gmail.com" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <GrMail />
            </a>
          </li>
          <li>
            <a href="https://github.com/Trast00" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaGithubSquare />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/dickoallassane/" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaLinkedin />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/AllassaneDicko0" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaTwitterSquare />
            </a>
          </li>
          <li>
            <a href="https://wellfound.com/u/allassane-dicko" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
              <FaAngellist />
            </a>
          </li>
        </ul>

        <div className="no-style animate animate-hide animate-delay-2s slide-fade-from-top">
          <a href="#contact">
            <button type="button" className="btn-action plausible-event-name=Hire+Me">Hire Me</button>
          </a>
          <button type="button" className="btn-action resume plausible-event-name=Download+Resume">
            <a href={resume} download="Allassane_Dicko_Resume.pdf" className="no-style">
              Download Resume
            </a>
          </button>
        </div>
      </div>
    </div>
  </section>
);

export default Headline;
