import PropTypes from 'prop-types';

const Block = (props) => {
  const { id, data, currentBlockId } = props;
  const { title, content } = data;
  return (
    <>

      <div
        id={id}
        className={`block-wrapper ${(id === 0) ? 'block-static' : 'block-dynamic'} ${currentBlockId < id && 'active'}`}
        style={{ zIndex: id }}
      >
        <div className="block-details">
          <h4>{title}</h4>
          <p>{content}</p>
        </div>
      </div>
    </>
  );
};

Block.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  currentBlockId: PropTypes.number.isRequired,
};

export default Block;
