import React, { useState } from 'react';
import './contact.css';
import { useInView } from 'react-intersection-observer';

const Contact = () => {
  const [inView, setInView] = useState(false);
  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView) => { if (inView) setInView(true); },
  });
  return (
    <section id="contact" className="flex-center column section contact">
      <h2 ref={ref} className={`section-title animate animate-hide ${inView && 'slide-fade-from-top'}`}>Let&apos;s work together on your next project</h2>
      <form
        ref={ref}
        action="https://formspree.io/f/xwkjdreo"
        method="post"
        className={`column animate animate-hide ${inView && 'slide-fade-from-right'} contact-form`}
      >
        <input type="email" name="email" placeholder="Email" required />
        <textarea name="message" id="message" cols="30" rows="10" placeholder="Message" required />
        <div className="flex-center">
          <button type="submit" className="btn-action">Get in touch</button>
        </div>
      </form>

    </section>
  );
};

export default Contact;
