import React from 'react';
import PropTypes from 'prop-types';

const Project = (props) => {
  const { data } = props;
  const {
    pausibleEventName,
    title,
    features,
    description,
    imgUrl,
    liveUrl,
    codeUrl,
    detailUrl,
  } = data;
  return (
    <>
      <div className="row wrap project">
        <img src={imgUrl} alt={`${title} project`} />
        <div className="column project-content">
          <h3>{title}</h3>
          <ul className="no-style row wrap project-features">
            {features.map((feature, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <p className="project-description">{description}</p>

          <div className="row wrap project-links">
            <a href={liveUrl} target="_blank" className={`no-style max-content-flex ${liveUrl === '' && 'hidden'} plausible-event-name=Live+Demo+${pausibleEventName}`} rel="noreferrer">
              <button type="button" className="max-width">Live Demo</button>
            </a>
            <a href={codeUrl} target="_blank" className={`no-style max-content-flex ${codeUrl === '' && 'hidden'} plausible-event-name=Source+Code+${pausibleEventName}`} rel="noreferrer">
              <button type="button" className="max-width">Source Code</button>
            </a>
            <button type="button" className={`no-style max-content-flex ${detailUrl === '' && 'hidden'}`}>5-min video</button>
          </div>
        </div>
      </div>
    </>
  );
};

Project.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
    liveUrl: PropTypes.string.isRequired,
    codeUrl: PropTypes.string.isRequired,
    detailUrl: PropTypes.string.isRequired,
    pausibleEventName: PropTypes.string.isRequired,
  }).isRequired,
};

export default Project;
