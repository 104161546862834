/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './projects.css';
import { useInView } from 'react-intersection-observer';
import img from '../../assets/images/254042573-c68486c0-0dbd-48b1-9167-7eafe4f5a996.png';
import Project from './Project';

const ListProjects = () => {
  const listProject = [
    {
      pausibleEventName: 'Meet+Africans',
      title: 'Meet Africans',
      features: ['React JS', 'Redux', 'Solo', 'Android Version Available (Kotlin)', 'Complex adaptable routing', 'Update profile data', 'Dynamic list of friends', 'SPA Dashboard', 'Highly responsive design'],
      description: 'Meet African Look no further than this dating web site! The dashboard allows you to quickly and easily connect with new users and start chatting with potential matches. You can edit and save your profile information. The project is highly mobile-friendly.',
      imgUrl: 'https://github.com/Trast00/Meet-Africans/assets/74411135/491c0a72-3dc5-49b9-9711-22900a26dab2',
      liveUrl: 'https://meet-africans.netlify.app/',
      codeUrl: 'https://github.com/Trast00/Meet-Africans',
      detailUrl: '',
    },
    {
      pausibleEventName: 'Food+On+Rails',
      title: 'Food On Rails',
      features: ['Ruby On Rails', 'PostgreSQL', 'Full Stack Rails App', 'CRUD', 'Pair Programming', 'RSpec', 'Testing', 'Authentification', 'Authorization', 'implement CRUD methods'],
      description: "Food-On-Rails is a website that allows users to manage their food inventory and create and publish recipes. The website provides an intuitive interface for users to easily add food items to their inventory and create and share recipes with others. It's a perfect solution for food enthusiasts looking to organize their ingredients and discover new recipes.",
      imgUrl: 'https://github.com/PrantoshB/Food-On-Rails/assets/74411135/eb35ce28-8b63-4bdb-aa4f-f0c46e1a80dc',
      liveUrl: 'https://food-on-rail.onrender.com/',
      codeUrl: 'https://github.com/PrantoshB/Food-On-Rails',
      detailUrl: '',
    },
    {
      pausibleEventName: 'Rent+A+House',
      title: 'Rent A House',
      features: ['React JS', 'Redux', 'Ruby on Rails', 'PostgreSQL', 'Jest', 'Testing', 'Back-end API with Rails', 'Front-end with React', 'Authentification', 'CRUD', 'Pair Programming'],
      description: 'Rent-A-House is an app that makes renting easy and convenient. You can browse through a variety of rental properties, add and delete them as you wish, and save your favorites for later. You can also create your own profile to store your personal information securely and access it anytime.',
      imgUrl: 'https://github.com/shella12/RentAnItemApp/assets/74411135/c9c995c9-560e-4a88-9e93-1913f15b1835',
      liveUrl: 'https://renthousefront.onrender.com/',
      codeUrl: 'https://github.com/shella12/RentAnItemApp',
      detailUrl: '',
    },
    {
      pausibleEventName: 'Block+Stack+Game',
      title: 'Block Stack Game',
      features: ['React JS', 'JavaScript', 'Fun Game', 'Complex interactions', 'Solo', 'Complex Game Logic', '3D game with CSS', 'Animation and Interaction on click', 'Responsive Design'],
      description: 'Stack-block-game is a little game where the goal is to stack a maximum number of moving blocks. Blocks will shrink if they are not stacked perfectly. This game is developed with React Js. The game is completely responsive and usable on mobile as all interactions are based on simple clicks (anywhere on the screen).',
      imgUrl: 'https://github.com/Trast00/stack-block-game/assets/74411135/3e6276fc-b8f4-4bed-9095-045518c56365',
      liveUrl: 'https://trast00-stack-block-game.netlify.app/',
      codeUrl: 'https://github.com/Trast00/stack-block-game',
      detailUrl: '',
    },
    {
      pausibleEventName: 'Meet+Africans+Android',
      title: 'Meet Africans (android)',
      features: ['Kotlin', 'XML', 'Firebase', 'Android Studio'],
      description: 'Rencontre Africaine is a meeting app dedicated to west African countries. Created with kotlin (Android studio), it contains a chat section, a discover section (list of users), and an event section (dedicated to all future features)',
      imgUrl: 'https://user-images.githubusercontent.com/74411135/254219475-3c524f53-430b-4f4a-83e1-ed6ff44f2ad0.png',
      liveUrl: 'https://play.google.com/store/apps/details?id=com.lnd.rencontreafrique&pli=1',
      codeUrl: 'https://github.com/Trast00/Meet-Africans-Android',
      detailUrl: '',
    },
    {
      pausibleEventName: 'ShowTime',
      title: 'ShowTime',
      features: ['JavaScript', 'API request', 'Pair Programming', 'HTML & CSS', 'API request', 'Add Like', 'Responsive Design', 'Add comments', 'Pair Programming'],
      description: 'ShowTime is a movie streaming website that uses TV maze API to display a list of movies with detail. Users can leave comments and like their preferred movies',
      imgUrl: 'https://github.com/Trast00/Capston-javascript/assets/74411135/b0c43fe1-488c-4392-bae9-10c7e02cbfc8',
      liveUrl: 'https://trast00.github.io/Capston-javascript/dist/index.html',
      codeUrl: 'https://github.com/Trast00/Capston-javascript',
      detailUrl: '',
    },
    {
      title: 'Currently working on Educp-SP',
      features: ['Node.js', 'Express.js', 'TypeScript', 'MongoDB', 'HTML & CSS', 'React.js', 'Startup product', 'Responsive Design'],
      description: 'This project is an educative website designed to enhance learning experiences for students. It provides a platform where students can register, login, and gain access to a wide range of educational resources. The website offers a variety of features to support learning, including exercise listings, corrections, filtering options, local teaching courses, profile management, scoring system, and an assistant chatbox powered by ChatAPI.',
      imgUrl: img,
      liveUrl: '',
      codeUrl: '',
      detailUrl: '',
    },
  ];

  const [inView, setInView] = useState(false);
  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView) => { if (inView) setInView(true); },
  });

  return (
    <section id="projects" className="section flex-center column projects">
      <h2 className="section-title">Featured Projects</h2>
      <main ref={ref} className={`flex-center max-width animate animate-hide ${inView && 'slide-fade-from-right'}`}>
        <ul className="no-style column max-width list-project">
          {listProject.map((project, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className="flex-center project-wrapper">
              <Project data={project} />
            </li>
          ))}
        </ul>
      </main>
    </section>
  );
};

export default ListProjects;
