import React from 'react';
import {
  FaGithubSquare, FaLinkedin, FaAngellist, FaTwitterSquare,
} from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import './footer.css';

const Footer = () => (
  <footer className="flex-center wrap max-content-flex footer">
    <ul className="no-style flex-center list-icons">
      <li>
        <a href="mailto:dickoallassane.dev@gmail.com" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
          <GrMail />
        </a>
      </li>
      <li>
        <a href="https://github.com/Trast00" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
          <FaGithubSquare />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/dickoallassane/" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
          <FaLinkedin />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/AllassaneDicko0" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
          <FaTwitterSquare />
        </a>
      </li>
      <li>
        <a href="https://wellfound.com/u/allassane-dicko" className="no-style social-icon plausible-event-name=Social+Medias" target="_blank" rel="noreferrer">
          <FaAngellist />
        </a>
      </li>
    </ul>
    <a href="mailto:dickoallassane.dev@gmail.com" className="no-style plausible-event-name=Social+Email" target="_blank" rel="noreferrer">
      <p>dickoallassane.dev@gmail.com</p>
    </a>
  </footer>
);

export default Footer;
