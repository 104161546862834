import React from 'react';
import PropTypes from 'prop-types';

const Recommandation = (props) => {
  const { data } = props;
  const {
    name,
    title,
    imgUrl,
    description,
    linkdin,
  } = data || {};
  return (
    <>
      <div className="recommandation">
        <div className="row head">
          <img src={imgUrl} alt={`${name}`} />
          <div className="recommandation-info">
            <a href={linkdin} target="_blank" rel="noreferrer" className="no-style">
              <h3 className="recommandation-name">{name}</h3>
            </a>
            <p className="recommandation-title desktop-only">
              <a href={linkdin} target="_blank" rel="noreferrer" className="no-style">
                {title}
              </a>
            </p>
          </div>
        </div>
        <div className="recommandation-content mobile-only">
          <p className="recommandation-title">
            <a href={linkdin} target="_blank" rel="noreferrer" className="no-style">
              {title}
            </a>
          </p>
        </div>
        <div className="recommandation-content">
          <p className="recommandation-description">{description}</p>
        </div>
      </div>
    </>
  );
};

Recommandation.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imgUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    linkdin: PropTypes.string.isRequired,
  }).isRequired,
};

export default Recommandation;
