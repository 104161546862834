/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';
import Recommandation from './Recommandation';
import './recommendations.css';

const Recommendations = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const listRecommandation = [
    {
      name: 'Roy (Ogato) Jumah',
      title: 'Full-stack Developer | Passionate about creating beautiful user interfaces | Experienced in JavaScript, React, Redux, and Ruby on Rails | Focused on seamless user experiences',
      imgUrl: 'https://avatars.githubusercontent.com/u/61903079?v=4',
      description: 'Allassane Dicko is a Micronaut who consistently stands out for his technical expertise and friendly demeanor. I have had the opportunity to work with Allassane on several projects and have been impressed by his contributions to each project. Allassane is a skilled software developer, able to quickly grasp new technologies and implement innovative solutions. He is also a strong team player, always working collaboratively with his colleagues to achieve project goals. In addition to his technical abilities, Allassane is known for his friendly and easy-going personality. He has a talent for making those around him feel at ease, and his positive energy creates a supportive and enjoyable work environment. I highly recommend Allassane for any project or opportunity. He is a talented and dedicated Micronaut who brings a unique combination of technical expertise and personal charm to every project.',
      linkdin: 'https://www.linkedin.com/in/roy-jumah/?locale=en_US',
    },
    {
      name: 'Abu Raihan',
      title: 'Full Stack Web Developer | JavaScript | Nodejs | Go | Ruby | React | Redux | Html | CSS | Graphic Designer',
      imgUrl: 'https://avatars.githubusercontent.com/u/35267447?v=4',
      description: 'I highly recommend Allassane Dicko as a talented and knowledgeable developer. With a proven track record of delivering outstanding results, he consistently demonstrates his mastery of the field, including meticulous attention to detail and strict adherence to industry best practices. Allassane is a collaborative team player, always putting the success of the project and the team first, making him a top choice for a team-oriented work environment.',
      linkdin: 'https://www.linkedin.com/in/raihan2bd/?locale=en_US',
    },
    {
      name: 'Steven Mukama',
      title: 'Full-Stack Web Developer | Javascript, React, Redux | Ruby, Ruby on Rail | Shopify',
      imgUrl: 'https://avatars.githubusercontent.com/u/57868344?v=4',
      description: 'It was a pleasure working alongside Allassane as a coding partner. Throughout the project, Allassane consistently demonstrated exceptional technical skills, particularly in Reactjs, HTML, and CSS. Not only was Allassane highly knowledgeable, but they also had a unique ability to explain complex coding concepts in a clear and concise manner, making it easy for our team to understand and implement their ideas.',
      linkdin: 'https://www.linkedin.com/in/stevenmukama/?locale=en_US',
    },
  ];

  const [inView, setInView] = useState(false);
  const { ref } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView) => { if (inView) setInView(true); },
  });

  return (
    <section id="recommandations" className="flex-center column section recommandations">
      <h2 className="section-title">Recommendations</h2>
      <main ref={ref} className={`flex-center max-width animate animate-hide ${inView && 'slide-fade-from-top'}`}>
        <ul className="no-style row max-width list-recommandation" style={{ transform: `translateX(calc((-100% * ${currentIndex}) - 30px))` }}>
          {listRecommandation.map((recommandation, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={`flex-center recommandation-wrapper ${(currentIndex === index && 'active')}`}>
              <Recommandation data={recommandation} />
            </li>
          ))}
        </ul>
        {currentIndex > 0 && (
          <div
            className="navigation-icon back"
            onClick={() => setCurrentIndex(currentIndex - 1)}
          >
            <MdOutlineArrowBackIosNew />
          </div>
        )}

        {currentIndex < listRecommandation.length - 1 && (
          <div
            className="navigation-icon next"
            onClick={() => setCurrentIndex(currentIndex + 1)}
          >
            <MdOutlineArrowForwardIos />
          </div>
        )}
      </main>
    </section>
  );
};

export default Recommendations;
