/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './navbar.css';
import { FiMenu } from 'react-icons/fi';
import { MdClose, MdWork } from 'react-icons/md';
import {
  FaHandshake, FaHome, FaPhoneAlt, FaSmile,
} from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className="row max-width header">
      <FiMenu className="mobile-only btn-menu" onClick={() => setIsOpen(!isOpen)} />
      <nav className={`row max-content-flex navbar ${isOpen && 'active'}`}>
        <MdClose className="row mobile-only btn-menu" onClick={() => setIsOpen(!isOpen)} />
        <ul className="no-style column list-nav" onClick={() => { setIsOpen(false); }}>
          <li>
            <a href="#headline" className="no-style row">
              <FaHome />
              <span>Presentation</span>
            </a>
          </li>
          <li>
            <a href="#projects" className="no-style row">
              <MdWork />
              <span>Projects</span>
            </a>
          </li>
          <li>
            <a href="#about" className="no-style row">
              <FaSmile />
              <span>About Me</span>
            </a>
          </li>
          <li>
            <a href="#recommandations" className="no-style row">
              <FaHandshake />
              <span>Recommandations</span>
            </a>
          </li>
          <li>
            <a href="#contact" className="no-style row">
              <FaPhoneAlt />
              <span>Contact Me</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
