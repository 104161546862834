import './components/Utils/utils.css';
import './App.css';
import './components/Utils/scrollbar.css';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Headline from './components/Headline/Headline';
import ListProjects from './components/Projects/ListProjects';
import Recommendations from './components/Recommendations/Recommendations';
import Navbar from './components/Navbar/Navbar';

const App = () => (
  <main className="App">
    <Navbar />
    <Headline />
    <ListProjects />
    <About />
    <Recommendations />
    <Contact />
    <Footer />
  </main>
);

export default App;
